@media screen and (max-width: 400px) {
	#about,
	#services,
	#testimonials,
	#team,
	#blog,
	#faq,
	#document,
	#contact,
	#footer {
		width: 111%;
	}
}
